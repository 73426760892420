import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ListItemLink from './sharedComponents/listItemLink';
import UserAvatar from './sharedComponents/userAvatar';
import {
  UIContext,
  Constants,
  useLocalStorage,
  AppType,
  useMsalUtils,
  loginRequestAdminWeb,
} from 'vcl-common';
import Channel from './mainComponents/channel';
import Channels from './mainComponents/channels';
import GlobalConfig from './mainComponents/globalConfig';
import GlobalAdmin from './mainComponents/globalAdmin';
import GlobalAdmins from './mainComponents/globalAdmins';
import Location from './mainComponents/location';
import Locations from './mainComponents/locations';
import Organization from './mainComponents/organization';
import Organizations from './mainComponents/organizations';
import Region from './mainComponents/region';
import Regions from './mainComponents/regions';
import User from './mainComponents/user';
import Users from './mainComponents/users';
import Webcast from './mainComponents/webcast';
import Webcasts from './mainComponents/webcasts';
import WebcastSummary from './mainComponents/webcastSummary';
import OrgSettings from './mainComponents/orgSettings';
import OrgSetting from './mainComponents/orgSetting';
import GlobalConfigValue from './mainComponents/globalConfigValue';
import ProgressSpinner from './sharedComponents/progressSpinner';
import { Link } from '@mui/material';
import Contact from './mainComponents/contact';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const drawerWidth = 200;

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
    width: '100%',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: {
    ...(theme.mixins.toolbar as any),
  },
  // toolbar: theme.mixins.toolbar,
}));

interface IAdminCenterProps {
  loading: boolean;
  error?: boolean;
  errorMessage?: string;
}

export default function AdminCenter(props: IAdminCenterProps) {
  const { signIn, signOut } = useMsalUtils({
    loginRequest: loginRequestAdminWeb,
  });
  const { classes } = useStyles() as any;
  const uiContext = useContext(UIContext);
  const [mobileOpen, setMobileOpen] = useState(false);
  const { getAuthResult, getGlobalSettings } = useLocalStorage();
  const userProfile = getAuthResult(AppType.AdminWeb)?.userProfile;
  const globalSettings = getGlobalSettings();

  document.title = 'Volvo Cars Live - Admin Center';

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = !props.loading && !props.error && (
    <div className="adminCenterToolbar">
      <div className={classes.toolbar} />
      <Divider />

      <List>
        {userProfile?.isWebcastAdminOfAny === true && (
          <ListItemLink
            to={Constants.routes.adminCenter.webcasts}
            primary="Webcasts"
          />
        )}
        {userProfile?.isChannelAdminOfAny === true && (
          <ListItemLink
            to={Constants.routes.adminCenter.channels}
            primary="Channels"
          />
        )}
      </List>
      <Divider />
      <List>
        {userProfile?.isRegionAdminOfAny === true && (
          <ListItemLink
            to={Constants.routes.adminCenter.regions}
            primary="Regions"
          />
        )}
        {userProfile?.isRegionAdminOfAny === true && (
          <ListItemLink
            to={Constants.routes.adminCenter.locations}
            primary="Locations"
          />
        )}
        {userProfile?.isOrgAdminOfAny === true && (
          <ListItemLink
            to={Constants.routes.adminCenter.orgSettings}
            primary={globalSettings?.organization?.title + ' Settings'}
          />
        )}
        {userProfile?.isOrgAdminOfAny === true && (
          <ListItemLink
            to={Constants.routes.adminCenter.organizations}
            primary="Organizations"
          />
        )}
        {userProfile?.isGlobalAdmin === true && (
          <ListItemLink
            to={Constants.routes.adminCenter.globalAdmins}
            primary="Global Admins"
          />
        )}
        {userProfile?.isGlobalAdmin === true && (
          <ListItemLink
            to={Constants.routes.adminCenter.users}
            primary="Users"
          />
        )}
      </List>
    </div>
  );

  return (
    <>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <AuthenticatedTemplate>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </AuthenticatedTemplate>
            <Typography
              variant="h6"
              noWrap
              sx={{ flexGrow: 1 }}
              component="div"
            >
              Volvo Cars Live - Admin Center
            </Typography>
            <AuthenticatedTemplate>
              <Link
                href={Constants.routes.adminCenter.contact}
                mr={2}
                underline="none"
                color="inherit"
              >
                Contact
              </Link>
              <UserAvatar />
              <IconButton
                color="inherit"
                aria-label="Sign out"
                edge="end"
                onClick={() => signOut()}
                size="large"
              >
                <LogoutIcon />
              </IconButton>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <IconButton
                color="inherit"
                aria-label="Sign in"
                edge="end"
                onClick={() => signIn()}
                size="large"
              >
                <LoginIcon />
              </IconButton>
            </UnauthenticatedTemplate>
          </Toolbar>
        </AppBar>
        <AuthenticatedTemplate>
          {props.loading && (
            <main className={classes.content}>
              <div className={classes.toolbar} />
              <ProgressSpinner />
            </main>
          )}
          {props.error && (
            <Dialog
              open={true}
              scroll="paper"
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
            >
              <DialogTitle id="scroll-dialog-title">Error</DialogTitle>
              <DialogContent dividers={false}>
                <Typography paragraph>
                  Something went wrong: {props.errorMessage}
                </Typography>
                <Typography paragraph>Please refresh to try again.</Typography>
              </DialogContent>
            </Dialog>
          )}
          {!props.error && !props.loading && (
            <Router>
              <nav className={classes.drawer} aria-label="main menu">
                <Hidden mdUp implementation="css">
                  <Drawer
                    variant="temporary"
                    anchor="left"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    onClick={handleDrawerToggle}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                      keepMounted: true, // Better open performance on mobile.
                    }}
                  >
                    {drawer}
                  </Drawer>
                </Hidden>
                <Hidden mdDown implementation="css">
                  <Drawer
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                  >
                    {drawer}
                  </Drawer>
                </Hidden>
              </nav>
              <main className={classes.content}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={uiContext.currentLocale}
                >
                  <div className={classes.toolbar} />
                  <Routes>
                    <Route
                      path={Constants.routes.adminCenter.webcasts}
                      element={<Webcasts />}
                    />
                    <Route
                      path={`${Constants.routes.adminCenter.webcasts}/:itemId`}
                      element={<Webcast />}
                    />
                    <Route
                      path={`${Constants.routes.adminCenter.webcastSummary}/:itemId`}
                      element={<WebcastSummary />}
                    />
                    <Route
                      path={Constants.routes.adminCenter.channels}
                      element={<Channels />}
                    />
                    <Route
                      path={`${Constants.routes.adminCenter.channels}/:itemId`}
                      element={<Channel />}
                    />
                    <Route
                      path={Constants.routes.adminCenter.regions}
                      element={<Regions />}
                    />
                    <Route
                      path={`${Constants.routes.adminCenter.regions}/:itemId`}
                      element={<Region />}
                    />
                    <Route
                      path={Constants.routes.adminCenter.locations}
                      element={<Locations />}
                    />
                    <Route
                      path={`${Constants.routes.adminCenter.locations}/:itemId`}
                      element={<Location />}
                    />
                    <Route
                      path={Constants.routes.adminCenter.globalConfig}
                      element={<GlobalConfig />}
                    />
                    <Route
                      path={`${Constants.routes.adminCenter.globalConfig}/:itemId`}
                      element={<GlobalConfigValue />}
                    />
                    <Route
                      path={Constants.routes.adminCenter.orgSettings}
                      element={<OrgSettings />}
                    />
                    <Route
                      path={`${Constants.routes.adminCenter.orgSettings}/:itemId`}
                      element={<OrgSetting />}
                    />
                    <Route
                      path={Constants.routes.adminCenter.organizations}
                      element={<Organizations />}
                    />
                    <Route
                      path={`${Constants.routes.adminCenter.organizations}/:itemId`}
                      element={<Organization />}
                    />
                    <Route
                      path={Constants.routes.adminCenter.users}
                      element={<Users />}
                    />
                    <Route
                      path={`${Constants.routes.adminCenter.users}/:itemId`}
                      element={<User />}
                    />
                    <Route
                      path={Constants.routes.adminCenter.globalAdmins}
                      element={<GlobalAdmins />}
                    />
                    <Route
                      path={`${Constants.routes.adminCenter.globalAdmins}/:itemId`}
                      element={<GlobalAdmin />}
                    />
                    <Route
                      path={Constants.routes.adminCenter.contact}
                      element={<Contact />}
                    />
                    <Route path="/" element={<Webcasts />} />
                  </Routes>
                </LocalizationProvider>
              </main>
            </Router>
          )}
        </AuthenticatedTemplate>
      </div>
    </>
  );
}

AdminCenter.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
