import { Grid, Paper } from '@mui/material';
import { useMemo } from 'react';
import {
  Constants,
  getHostDependentImageUrl,
  useVclApi,
  HTTP_METHODS,
  VclApiGetType,
  IRegion,
  ILocation,
  VclApiProps,
  AppType,
} from 'vcl-common';
import ContactCard from '../sharedComponents/contactCard';

export default function Contact() {
  const regionApiProps = useMemo<VclApiProps>(() => {
    return {
      apiUrl: Constants.routes.api.regions,
      method: HTTP_METHODS.GET,
      appType: AppType.AdminWeb,
      getType: VclApiGetType.All,
      useMsalAuthorization: true,
      fetchImmediately: true,
    };
  }, []);
  const { apiResponseItems: regions, apiRequestInProgress: regionsInProgress } =
    useVclApi<IRegion>(regionApiProps);

  const locationApiProps = useMemo<VclApiProps>(() => {
    return {
      apiUrl: Constants.routes.api.locations,
      method: HTTP_METHODS.GET,
      appType: AppType.AdminWeb,
      getType: VclApiGetType.All,
      useMsalAuthorization: true,
      fetchImmediately: true,
    };
  }, []);
  const {
    apiResponseItems: locations,
    apiRequestInProgress: locationsInProgress,
  } = useVclApi<ILocation>(locationApiProps);

  const regionInfo =
    !regionsInProgress && !locationsInProgress && regions && locations
      ? regions.map((r) => {
          const locs = locations
            .filter((l) => l.regionId === r.id)
            .map((l) => {
              return {
                title: l.title,
                technicians: l.technicians.map((t: any) => t.user),
              };
            });
          return {
            title: r.title,
            locations: locs,
            admins: r.permissions.map((t: any) => t.user),
            thumbUrl: getHostDependentImageUrl(r.thumbnailUrl),
            heroUrl: getHostDependentImageUrl(r.heroUrl),
          };
        })
      : [];

  return (
    <>
      <h2>Contact</h2>
      {regionInfo.map((region) => {
        return (
          <>
            <Paper>
              <h3>{region.title}</h3>
              <Grid container spacing={3}>
                {region.admins.map((admin: any) => (
                  <Grid xs={12} md="auto">
                    <ContactCard
                      name={admin.fullName}
                      upn={admin.userName}
                      email={admin.userName}
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={3}>
                {region.locations.map((loc: any) => (
                  <Grid xs={12} md="auto">
                    {loc.title}
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </>
        );
      })}
    </>
  );

  // return (
  //   <>
  //     <Grid container spacing={2}>
  //       {regionInfo.map((region) => {
  //         return (
  //           <Grid xs="12" md="6">
  //             <Card sx={{ maxWidth: 345 }}>
  //               <CardHeader title={region.title} />
  //               <CardMedia
  //                 component="img"
  //                 height="194"
  //                 image={region.heroUrl}
  //                 alt={region.title}
  //               />
  //               <CardContent>
  //                 <Typography>Administrators</Typography>
  //                 {region.admins.map(admin => (<ContactCard name={admin.fullName} upn={admin.userName} email={admin.userName} />))}
  //               </CardContent>
  //             </Card>
  //           </Grid>
  //         );
  //       })}
  //     </Grid>
  //   </>
  // );
}
