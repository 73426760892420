import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import { format, parseISO } from 'date-fns';
import { useContext, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  UIContext,
  Constants,
  HTTP_METHODS,
  VclApiProps,
  VclApiGetType,
  useVclApi,
  IWebcast,
  useLocalStorage,
  AppType,
} from 'vcl-common';
import ProgressSpinner from '../sharedComponents/progressSpinner';

const useStyles = makeStyles()((theme) => ({
  canvas: {
    padding: theme.spacing(3),
    maxWidth: 1200,
  },
}));

export default function WebcastSummary(props: any) {
  const { itemId } = useParams() as any;
  const { classes } = useStyles();
  const [webcast, setWebcast] = useState<IWebcast>();
  const [loading, setLoading] = useState(true);
  const uiContext = useContext(UIContext);
  const navigate = useNavigate();

  const apiGetProps = useMemo<VclApiProps>(() => {
    return {
      apiUrl: `${Constants.routes.api.webcasts}/${parseInt(itemId)}`,
      method: HTTP_METHODS.GET,
      appType: AppType.AdminWeb,
      getType: VclApiGetType.Item,
      useMsalAuthorization: true,
      fetchImmediately: true,
    };
  }, [itemId]);
  const { apiResponse, apiRequestInProgress } =
    useVclApi<IWebcast>(apiGetProps);

  const { getGlobalSettings } = useLocalStorage();
  const globalSettings = getGlobalSettings();

  if (loading && !apiRequestInProgress && !!apiResponse?.data) {
    setWebcast(apiResponse.data);
    setLoading(false);
  }

  const getLocalDate = (dt: string | undefined) => {
    return dt
      ? format(parseISO(dt + 'Z'), 'P p', {
          locale: uiContext.currentLocale as any,
        })
      : '[no date selected]';
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <h2>Webcast</h2>
        </Grid>
      </Grid>
      <Paper className={classes.canvas}>
        {loading && <ProgressSpinner />}
        {!loading && (
          <>
            {webcast && <h2>{webcast.titleCombined}</h2>}
            {webcast && (
              <div>
                <h3>When and where</h3>
                The webcast is scheduled between{' '}
                {getLocalDate(webcast?.startTime)} and{' '}
                {getLocalDate(webcast?.endTime)}
                {webcast.location && `, at location ${webcast.location.title}`}
                <h3>How to watch</h3>
                {webcast.requireInvite === true && (
                  <strong>Only invited users may access this webcast!</strong>
                )}
                <div>To join this webcast, navigate to</div>
                <ul>
                  {globalSettings?.organization?.webHostNamesArr.map(
                    (value: any) => {
                      const protocol = value.match(/localhost/i)
                        ? 'http://'
                        : 'https://';
                      const url = protocol + value + webcast.relativeUrl;
                      return (
                        <li key={value}>
                          <a href={url} target="_blank" rel="noreferrer">
                            {url}
                          </a>
                        </li>
                      );
                    },
                  )}
                </ul>
                <h3>Contact information</h3>
                <div>
                  Contact email:{' '}
                  {webcast.contactEmail
                    ? webcast.contactEmail
                    : '[no contact email available]'}
                </div>
                <h3>Streaming specifications</h3>
                <div></div>
              </div>
            )}
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              id="buttonsGrid"
            >
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() =>
                    navigate(
                      `${Constants.routes.adminCenter.webcasts}/${itemId}`,
                    )
                  }
                  color="secondary"
                >
                  {webcast?.isDeleted ? 'View' : 'Edit'}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() =>
                    navigate(Constants.routes.adminCenter.webcasts)
                  }
                  color="secondary"
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
    </>
  );
}
